import './style.css';

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import EnquiryModal from '../EnquiryModal';
import LoginDropdown from '../LoginDropdown';

export default function Hamburger() {
  const [offer, setoffer] = useState(false);
  const [resource, setresource] = useState(false);
  function changeTheCheckbox() {
    const element = document.getElementById("hamburger");
    element.checked = false;
  }
  function showDropDown() {
    setoffer(!offer);
    setresource(false);
  }
  return (
    <>
      <div class="ham_navbar">
        <div class="ham_container ham_nav-container">
          <input class="checkbox" type="checkbox" name="" id="hamburger" />
          <div class="hamburger-lines">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>
          <div class="ham_menu-items">
            <ul className="ham_menu_wrap">
              <li onClick={changeTheCheckbox}>
                <NavLink exact to="/">
                  Home
                </NavLink>
              </li>
              <li onClick={changeTheCheckbox}>
                <NavLink exact to="/about-us">
                  About
                </NavLink>
              </li>

              <li
                onClick={() => {
                  showDropDown("offer_drp");
                }}
              >
                Solutions
                {offer ? (
                  <ul className="offer_drp_dwn" id="offer_drp">
                    <NavLink
                      onClick={changeTheCheckbox}
                      exact
                      to="/solutions/negotiation"
                    >
                      Negotiation
                    </NavLink>
                    <NavLink
                      onClick={changeTheCheckbox}
                      exact
                      to="/solutions/mediation"
                    >
                      Mediation
                    </NavLink>
                    <NavLink
                      onClick={changeTheCheckbox}
                      exact
                      to="/solutions/arbitration"
                    >
                      Arbitration
                    </NavLink>
                  </ul>
                ) : (
                  <></>
                )}
              </li>
              <li
                onClick={() => {
                  setresource(!resource);
                  setoffer(false);
                }}
              >
                Resources
                {resource ? (
                  <ul className="offer_drp_dwn" id="resource_drp">
                    <NavLink
                      exact
                      onClick={changeTheCheckbox}
                      to="/clause"
                      target="_blank"
                    >
                      Clause
                    </NavLink>
                    <NavLink
                      exact
                      onClick={changeTheCheckbox}
                      to="https://trainings.presolv360.com/"
                      target="_blank"
                    >
                      ODR Course
                    </NavLink>
                    <NavLink exact onClick={changeTheCheckbox} to="/case-alert">
                      Case Alerts
                    </NavLink>
                    <NavLink
                      exact
                      onClick={changeTheCheckbox}
                      to="https://presolv360.com/resources/"
                      target="_blank"
                    >
                      Blog
                    </NavLink>
                    <NavLink exact onClick={changeTheCheckbox} to="/odr-tv">
                      ODR TV
                    </NavLink>
                    <NavLink
                      exact
                      onClick={changeTheCheckbox}
                      to="/research-paper"
                    >
                      Research Papers
                    </NavLink>
                    <NavLink exact onClick={changeTheCheckbox} to="/odr-on-air">
                      {" "}
                      ODR on Air
                    </NavLink>
                    <NavLink exact onClick={changeTheCheckbox} to="/chat360">
                      Chat360
                    </NavLink>
                    <NavLink exact onClick={changeTheCheckbox} to="/impression">
                      Impressions
                    </NavLink>
                    <NavLink
                      exact
                      onClick={changeTheCheckbox}
                      to="/globally-speaking"
                    >
                      Globally Speaking
                    </NavLink>
                    <NavLink exact onClick={changeTheCheckbox} to="/op-ed">
                      Op-ed
                    </NavLink>
                    {/* <NavLink exact  onClick={changeTheCheckbox} to="/Template_Library">Template Library</NavLink> */}
                  </ul>
                ) : (
                  <></>
                )}
              </li>
              {/* <li onClick={changeTheCheckbox}>
              <NavLink exact  to="/ResourceCenter">Resource Center</NavLink>
            </li> */}
              <li onClick={changeTheCheckbox}>
                <NavLink exact to="/career">
                  Careers
                </NavLink>
              </li>
              <li onClick={changeTheCheckbox}>
                <EnquiryModal tittle={"Business Enquiry"} />
              </li>
              <li onClick={changeTheCheckbox}>
                <LoginDropdown />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
