import "../src/assets/styles/Resource.css";
import "./assets/styles/AboutPage.css";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import AOS from "aos";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import Cookie from "./components/Cookie";
import ScrollToTop from "./components/ScrollToTop";
import About from "./pages/AboutPage";
import Career from "./pages/Career";
import Clauses from "./pages/Clauses";
import CookiesPolicy from "./pages/ExtraPages/CookiesPolicy";
import FAQ from "./pages/ExtraPages/FAQ";
import PrivacyPolicy from "./pages/ExtraPages/PrivacyPolicy";
import RulesCode from "./pages/ExtraPages/RulesCode";
import Standard from "./pages/ExtraPages/Standard";
import TermsCondition from "./pages/ExtraPages/TermsCondition";
import Home from "./pages/Home";
import Arbitration from "./pages/OurOffering/Arbitration";
import Mediation from "./pages/OurOffering/Mediation";
import Negotiation from "./pages/OurOffering/Negotiation";
import ResourceCenter from "./pages/resourceCenter";
import CaseAlert from "./pages/Resources/CaseAlert";
import Chat360 from "./pages/Resources/Chat360";
import GlobalSpeaking from "./pages/Resources/GlobalSpeaking";
import OdrTv from "./pages/Resources/OdrTv";
import OpEd from "./pages/Resources/Op_ed";
import Podcast from "./pages/Resources/Podcast";
import ResearchPaper from "./pages/Resources/ResearchPaper";
import RoleTemplateSearch from "./pages/Resources/RoleTemplateSearch";
import TemplateLibrary from "./pages/Resources/TemplateLibrary";
import TemplatePreview from "./pages/Resources/TemplatePreview";
import Footer from "./Section/Footer";
import Navbar from "./Section/navbar/Navbar";

function App() {
  useEffect(() => {
    AOS.init();
  });
  return (
    <>
      <div className="main">
        <Cookie />
        <Navbar />
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/career" element={<Career />} />
          <Route path="/impression" element={<ResourceCenter />} />
          <Route path="/solutions/negotiation" element={<Negotiation />} />
          <Route path="/solutions/mediation" element={<Mediation />} />
          <Route path="/solutions/arbitration" element={<Arbitration />} />
          <Route path="/odr-on-air" element={<Podcast />} />
          <Route path="/odr-tv" element={<OdrTv />} />
          <Route path="/globally-speaking" element={<GlobalSpeaking />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/case-alert" element={<CaseAlert />} />
          <Route path="/research-paper" element={<ResearchPaper />} />
          <Route path="/odr-standard" element={<Standard />} />
          <Route path="/cookie-policy" element={<CookiesPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />
          <Route path="/fees" element={<RulesCode />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="/clause" element={<Clauses />} />
          <Route path="/chat360" element={<Chat360 />} />
          <Route path="/op-ed" element={<OpEd />} />
          <Route path="/Template_Library" element={<TemplateLibrary />} />
          <Route
            path="/Template_Library/:role"
            element={<RoleTemplateSearch />}
          />
          <Route path="/TemplatePreview/:id" element={<TemplatePreview />} />
        </Routes>

        <Footer />
      </div>
    </>
  );
}

export default App;
