import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

export default function TemplatePreview() {
  const { id } = useParams();
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("Loading...");

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      try {
        const response = await axios.get("https://3.110.198.31/api/templates");
        const templates = response.data?.data;

        // Find the template that matches the ID from the URL
        const matchedTemplate = templates.find((t) => t.id.toString() === id);

        if (matchedTemplate) {
          setTemplate(matchedTemplate);
          setTitle(matchedTemplate.Label);
        } else {
          toast.error("Template with specified ID not found.");
          setTitle("Template Not Found");
        }
      } catch (error) {
        toast.error("Failed to fetch templates. Please try again.");
        console.error("Error fetching templates:", error);
        setTitle("Error Fetching Templates");
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, [id]);

  const handleOpenInNewWindow = () => {
    if (!template) {
      toast.error("No template content to display.");
      return;
    }

    const content = template.HTML;
    const filename = template.Label.replace(/\s+/g, "_");
    const newWindow = window.open("", "_blank");
    newWindow.document.open();

    // Adding CSS for border and basic styling
    newWindow.document.write(`
    <html>
      <head>
        <title>${template.Label}</title>
        <style>
          body { font-family: Arial, sans-serif; padding: 20px; text-align:center}
          .template-content { border: 2px solid #ccc; padding: 20px; margin:auto; width:800px }
          button { margin-top: 10px; padding: 8px 16px; font-size: 16px; cursor: pointer; }
        </style>
        <script>
          function download(format) {
            var content = ${JSON.stringify(content)};
            var filename = "${filename}";
            if (format === "pdf") {
              // PDF download script goes here
            } else {
              var blob = new Blob([content], { type: format === "doc" ? "application/msword" :
                  "text/html" });
              var downloadLink = document.createElement("a");
              downloadLink.href = URL.createObjectURL(blob);
              downloadLink.download = filename + (format === "doc" ? ".doc" : ".html");
              downloadLink.click();
            }
          }
        </script>
      </head>
      <body>
      <h1 style="text-align:center">${template.Label}</h1>
        <div class="template-content">${content}</div>
        <button onclick="download('html')">Download HTML</button>
        <button onclick="download('doc')">Download DOC</button>
      </body>
    </html>
  `);
    newWindow.document.close();
  };

  const handleDownload = async (format) => {
    if (!template) {
      toast.error("No template content to download.");
      return;
    }

    const content = template.HTML;
    const filename = template.Label.replace(/\s+/g, "_");

    if (format === "pdf") {
      // Dynamically import jsPDF
      const { jsPDF } = await import("jspdf");
      const pdf = new jsPDF();

      pdf.html(content, {
        callback: function (doc) {
          doc.save(`${filename}.pdf`);
        },
        x: 10,
        y: 10,
        windowWidth: pdf.internal.pageSize.getWidth(),
        windowHeight: pdf.internal.pageSize.getHeight(),
      });
      return;
    }

    // Handle other formats (HTML, DOC)
    const blob = new Blob([content], { type: "text/html" });
    const downloadLink = document.createElement("a");

    if (format === "html") {
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${filename}.html`;
    } else if (format === "doc") {
      const docBlob = new Blob(
        [
          `
        <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns="http://www.w3.org/TR/REC-html40">
        <head><meta charset="utf-8"></head><body>${content}</body></html>
        `,
        ],
        { type: "application/msword" }
      );
      downloadLink.href = URL.createObjectURL(docBlob);
      downloadLink.download = `${filename}.doc`;
    }

    if (downloadLink) {
      downloadLink.click();
    }
  };

  return (
    <div className="templatePage">
      <ToastContainer />
      <section className="landing_wrap">
        {loading ? (
          <h1 style={{ padding: "0 1.5em" }}>{title}</h1>
        ) : (
          <h1 style={{ padding: "0 1.5em" }}>{title}</h1>
        )}
      </section>
      <div className="preview-options">
        <div className="dropdown-wrap">
          <button className="blue_btn" onClick={handleOpenInNewWindow}>
            Open in New Window
          </button>
          <button className="blue_btn">Download</button>
          <div className="dropdown-content">
            <button onClick={() => handleDownload("html")}>HTML</button>
            <button onClick={() => handleDownload("doc")}>DOC</button>
            {/* <button onClick={() => handleDownload("pdf")}>PDF</button> */}
          </div>
        </div>
        <div className="preview-container">
          {loading ? (
            <p>Loading template preview...</p>
          ) : template ? (
            <div
              className="preview-box"
              dangerouslySetInnerHTML={{
                __html: template.HTML || "No preview available",
              }}
            />
          ) : (
            <p>No preview available for the selected template.</p>
          )}
        </div>
      </div>
    </div>
  );
}
