import React from "react";

export default function MileStone_mobile() {
  return (
    <div className="Mobile_milestone_wrap">
      <div
        className="core_mobile_card"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <h3>2017</h3>
        <p>
          Blending research, innovation, tech, and empathy, Presolv360 was
          founded to make dispute resolution simple and user-centric
        </p>
      </div>
      <div
        className="core_mobile_card"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <h3>2020</h3>
        <p>
          Included as an ADR/ODR institution in the notification issued by
          Department of Legal Affairs, Ministry of Law and Justice
        </p>
      </div>
      <div
        className="core_mobile_card"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <h3 className="no_wrap">2020-21</h3>
        <p>
          Launched proprietary mediation and arbitration modules and empanelled
          as a Mediation Institute by the Bombay High Court
        </p>
      </div>
      <div
        className="core_mobile_card"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <h3 className="no_wrap">2022-23</h3>
        <p>
          Incubated by Cyril Amarchand Mangaldas, empanelled by 8 Courts and
          integrated into the securities market ecosystem as per SEBI Master
          Circular on ODR
        </p>
      </div>
      <div
        className="core_mobile_card"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <h3>2023</h3>
        <p>
          At the onset, parties in 7,257 pin codes saved INR 362 million in
          costs and 7.8 million days following record participation and
          settlements
        </p>
      </div>
    </div>
  );
}
