import '../../assets/styles/Roletemplatesearch.css';

import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import { ArrowUpRight } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

export default function RoleTemplateSearch() {
  const { role } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 6;

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://3.110.198.31/api/templates?filters%5BCategory%5D%5B$eq%5D=${role}`
        );
        setTemplates(response.data.data || []);
      } catch (error) {
        toast.error("Failed to fetch templates. Please try again.");
        console.error("Error fetching templates:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, [role]);

  const filteredTemplates = templates.filter((template) =>
    template.Label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalItems = filteredTemplates.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredTemplates.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleOpenInNewWindow = (template) => {
    const content = template.HTML;
    const filename = template.Label.replace(/\s+/g, "_");
    const newWindow = window.open("", "_blank");
    newWindow.document.open();

    newWindow.document.write(`
    <html>
      <head>
        <title>${template.Label}</title>
        <style>
          body { font-family: Arial, sans-serif; padding: 20px; text-align:center}
          .template-content { border: 2px solid #ccc; padding: 20px; margin:auto; width:800px }
          button { margin-top: 10px; padding: 8px 16px; font-size: 16px; cursor: pointer; }
        </style>
        <script>
          function download(format) {
            var content = ${JSON.stringify(content)};
            var filename = "${filename}";
            if (format === "pdf") {
              // PDF download script goes here
            } else {
              var blob = new Blob([content], { type: format === "doc" ? "application/msword" :
                  "text/html" });
              var downloadLink = document.createElement("a");
              downloadLink.href = URL.createObjectURL(blob);
              downloadLink.download = filename + (format === "doc" ? ".doc" : ".html");
              downloadLink.click();
            }
          }
        </script>
      </head>
      <body>
      <h1 style="text-align:center">${template.Label}</h1>
        <div class="template-content">${content}</div>
        <button onclick="download('html')">Download HTML</button>
        <button onclick="download('doc')">Download DOC</button>
      </body>
    </html>
  `);
    newWindow.document.close();
  };

  return (
    <div className="container_1">
      <ToastContainer />
      <div className="search-bar">
        <div className="search-input-container">
          <input
            type="text"
            placeholder={`Search templates for ${role}`}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1);
            }}
            className="search-input"
          />
        </div>
        <button className="search-button">Search</button>
      </div>

      <div className="template-grid">
        {loading ? (
          <p>Loading templates...</p>
        ) : (
          currentItems.map((template) => (
            <div
              key={template.id}
              className="card_2"
              onClick={() => handleOpenInNewWindow(template)}
            >
              <div className="card-content">
                <span className="card-title">{template.Label}</span>
                <ArrowUpRight className="card-icon_2" />
              </div>
            </div>
          ))
        )}
        {currentItems.length === 0 && <p>No templates found.</p>}
      </div>

      <div className="pagination-container">
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        )}
      </div>
    </div>
  );
}
